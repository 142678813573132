<template>
  <Loading v-if="isLoading" />
  <div v-else>
    <Sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <AdminNavbar />
      <div class="relative bg-lightBlue-600 hidden md:flex lg:flex" style="padding-top:75px"></div>
      <div class="px-4 md:px-10 mx-auto w-full pt-8" style="min-height:80vh;">
        <router-view />
      </div>
      <div class="px-4 md:px-10 mx-auto w-full pt-8">
        <FooterAdmin />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { AdminNavbar,Sidebar,FooterAdmin } from "@/components";
  import { onMounted,computed } from 'vue';
  import { AuthService } from '@/services';
  import { Loading } from "@/components";
  import store from '@/store';

  const auth = new AuthService();
  const accessToken = computed(() => store.getters.accessToken);
  const refresh_token = computed(() => store.getters.refreshToken);
  const userinfo = computed(() => store.getters.userinfo);
  const isLoading = computed(() => store.getters.loading);

  onMounted(async () => {
    if (accessToken.value === null || accessToken.value === undefined || String(accessToken.value).trim() === "") {
      if (refresh_token.value !== null && refresh_token.value !== undefined && String(refresh_token.value).trim() !== "") {
        let user = await auth.refreshAsync();

        if (user) {
          store.dispatch('saveUserInfo', { userinfo: user });
          store.dispatch('saveTokens', { accessToken: user?.access_token, refreshToken: user?.refresh_token });
        } else {
          //document.location.href = "/";  
        }
      } else {
        //document.location.href = "/";
      }
    }

    const currentTime = Date.now() / 1000;
    const bufferTime = 60;

    if (userinfo.value.expires_at && userinfo.value.expires_at - currentTime < bufferTime) {
      let user = await auth.refreshAsync();
      store.dispatch('saveUserInfo', { userinfo: user });
      store.dispatch('saveTokens', { accessToken: user?.access_token, refreshToken: user?.refresh_token });
    }
  });
</script>
