import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';
import { TranslationRepository, MemberRepository } from './repositories';
import { AuthService } from '@/services';

const auth = new AuthService();

const i18n = createI18n({
  legacy: false,
  locale: 'en', // 기본 언어 설정
  messages: {}, // 초기 메시지는 비어 있습니다.
});

let translationRep = new TranslationRepository();
let memberRep = new MemberRepository();

async function loadLocaleMessages(locale: string) {
  try {
    const response = await translationRep.GetTranslations(locale);
    i18n.global.setLocaleMessage(locale, response.data);
  } catch (error) {
    console.error(`Failed to load ${locale} locale messages:`, error);
  }
}

async function setupI18n() {
  const response = await translationRep.GetLanguages();
  if (response !== null && response !== undefined && response.check) {
    for (let i = 0; i < response.data.length; i++) {
      await loadLocaleMessages(response.data[i].languageCode);
    }
  }
}

async function initializeAuth() {
  let user = await auth.getUser();

  if (user) {
    const currentTime = Date.now() / 1000;
    const bufferTime = 60;

    if (user.expires_at && user.expires_at - currentTime < bufferTime) {
      user = await auth.refreshAsync();
    }

    let rst = await memberRep.GetUser();
    if (rst !== null && rst !== undefined) {
      if (rst.check && rst.data !== null && rst.data !== undefined) {
        store.dispatch('saveUserInfo', { userinfo: rst.data });
      }
    }
  }
}

store.dispatch('setLoading', true); // 로딩 시작

initializeAuth().then(async () => {
  await setupI18n();
  setTimeout(() => {
    const app = createApp(App);
    app.use(store);
    app.use(router);
    app.use(i18n);

    app.mount('#app');

    store.dispatch('setLoading', false); // 로딩 종료
  }, 1);
});
