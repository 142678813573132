import { IPageBase } from "@/abstracts";
import config from "@/config";
import { Site } from "@/entities";
import { PagingParameter, ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class SiteRepository {
    async GetSites(paging:IPageBase):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/DynamicSite/Sites?curPage=${paging.curPage}&pageSize=${paging.pageSize}`;
        console.log('SiteRepository GetSites : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetRecentSites(count:number):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/DynamicSite/Sites/Recent/${count}`;
        console.log('SiteRepository GetRecentSites : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetSite(id:string):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/DynamicSite/Site/${id}`;
        console.log('SiteRepository GetSite : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetSiteDuplicate(slug:string, id:string|null):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/DynamicSite/Site/Duplicate?slug=${slug}`;
        if (id !== null && String(id).trim() !== "") {
            url += `id=${id}`;
        }
        console.log('SiteRepository GetSiteDuplicate : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async CreateSite(site:Site):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/DynamicSite/Create`;
        console.log('MemberRepository CreateSite : ', url);
        return await ApiHelper.PostAsync(url, site);
    }

    async UpdateSite(site:Site):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/DynamicSite/Update`;
        console.log('MemberRepository UpdateSite : ', url);
        return await ApiHelper.PutAsync(url, site);
    }

    async DeleteSite(id:string):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/DynamicSite/Delete/${id}`;
        console.log('MemberRepository DeleteSite : ', url);
        return await ApiHelper.DeleteAsync(url);
    }
}
