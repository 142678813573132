import config from "@/config";
import { ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class FileRepository {
    private slug:string = "builder";

    async FileUpload(file:any):Promise<ReturnValues> {
        const formData = new FormData();
        formData.append('upload', file);
        let url = `${config.apis.resource}/api/File/Album/${this.slug}/Upload`;
        console.log('FileRepository FileUpload');
        return await ApiHelper.UploadAsync(url, formData);
    }

    async GetImages():Promise<ReturnValues> {
        let  url = `${config.apis.resource}/api/File/Album/${this.slug}/List`;
        console.log('FileRepository GetImages');
        return await ApiHelper.GetAsync(url);
    }

    async FileRemove(id:string):Promise<ReturnValues> {
        let  url = `${config.apis.resource}/api/File/Delete/${id}`;
        console.log('FileRepository FileRemove');
        return await ApiHelper.DeleteAsync(url);
    }
}
