<template>
<div class="bg-white shadow-sm p-4">
<form @submit.prevent="validateForm" class="space-y-4">
    <div class="form-group mt-3">
    <label for="currentPassword" class="block text-gray-700">Current Password</label>
    <input v-model="form.currentPassword" type="password" id="currentPassword" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your current password">
    <p v-if="errors.currentPassword" class="text-red-500 text-xs">{{ errors.currentPassword }}</p>
    </div>
    <div class="form-group mt-3">
    <label for="newPassword" class="block text-gray-700">New Password</label>
    <input v-model="form.newPassword" type="password" id="newPassword" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your new password">
    <p v-if="errors.newPassword" class="text-red-500 text-xs">{{ errors.newPassword }}</p>
    </div>
    <div class="form-group mt-3">
    <label for="confirmNewPassword" class="block text-gray-700">Confirm New Password</label>
    <input v-model="form.confirmNewPassword" type="password" id="confirmNewPassword" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Confirm your new password">
    <p v-if="errors.confirmNewPassword" class="text-red-500 text-xs">{{ errors.confirmNewPassword }}</p>
    </div>
    <div class="flex justify-center mt-6">
    <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Change Password</button>
    </div>
</form>
</div>
</template>
  
  <script setup lang="ts">
  import { ref, reactive } from 'vue';
  
  interface Form {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }
  
  const form = reactive<Form>({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  
  const errors = reactive<Partial<Form>>({});
  
  const validateForm = () => {
    clearErrors();
    
    if (!form.currentPassword) errors.currentPassword = 'Current password is required.';
    if (!form.newPassword) errors.newPassword = 'New password is required.';
    if (form.newPassword && form.newPassword.length < 8) errors.newPassword = 'New password must be at least 8 characters long.';
    if (form.newPassword !== form.confirmNewPassword) errors.confirmNewPassword = 'Passwords do not match.';
  
    if (Object.values(errors).every(error => !error)) {
      // Save the form data
      console.log('Form data:', form);
    }
  };
  
  const clearErrors = () => {
    Object.keys(errors).forEach(key => {
      errors[key as keyof Form] = '';
    });
  };
  </script>
  
  <style scoped>
  .form-input {
    border: 1px solid #d1d5db;
    padding: 0.5rem;
    border-radius: 0.375rem;
  }
  .form-input:focus {
    border-color: #3b82f6;
    outline: none;
  }
  </style>
  