<template>
  <div class="bg-white shadow-sm p-4">
      <h1 class="text-2xl font-bold text-blue-600 mb-6">Template Configuration</h1>

      <!-- Main Tab -->
      <div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Name -->
          <div>
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <input v-model="pageinfo.template.name" type="text" id="name" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter website name">
          </div>
  
          <!-- Slug -->
          <div>
            <label for="slug" class="block text-sm font-medium text-gray-700">Slug</label>
            <div class="w-full justify-center">
            <input v-model="pageinfo.template.slug" type="text" id="slug" class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm mr-1" placeholder="Enter website slug">
            </div>
          </div>

        </div>
      </div>
  
      <!-- Details Tab -->
      <div class="mt-4">
        <div class="flex border-b border-gray-200">
            <button @click="currentSubTab = 1" :class="{'bg-lightBlue-600 text-white': currentSubTab === 1, 'text-gray-200': currentSubTab !== 1}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Header</button>
            <button @click="currentSubTab = 2" :class="{'bg-lightBlue-600 text-white': currentSubTab === 2, 'text-gray-200': currentSubTab !== 2}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Footer</button>
            <button @click="currentSubTab = 3" :class="{'bg-lightBlue-600 text-white': currentSubTab === 3, 'text-gray-200': currentSubTab !== 3}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Description</button>
            <button @click="currentSubTab = 4" :class="{'bg-lightBlue-600 text-white': currentSubTab === 4, 'text-gray-200': currentSubTab !== 4}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Scripts</button>
            <button @click="currentSubTab = 5" :class="{'bg-lightBlue-600 text-white': currentSubTab === 5, 'text-gray-200': currentSubTab !== 5}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Head</button>
            <button @click="currentSubTab = 6" :class="{'bg-lightBlue-600 text-white': currentSubTab === 6, 'text-gray-200': currentSubTab !== 6}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Body</button>
        </div>

        <div class="grid grid-cols-1">
          <!-- Header -->
          <div v-if="currentSubTab === 1">
            <v-ace-editor v-model:value="pageinfo.template.header" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>
  
          <!-- Footer -->
          <div v-if="currentSubTab === 2">
            <v-ace-editor v-model:value="pageinfo.template.footer" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>
  
          <!-- Description -->
          <div v-if="currentSubTab === 3">
            <v-ace-editor v-model:value="pageinfo.template.description" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>
  
          <div v-if="currentSubTab === 4">
            <v-ace-editor v-model:value="pageinfo.template.scripts" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>

          <!-- Styles -->
          <div v-if="currentSubTab === 5">
            <v-ace-editor v-model:value="pageinfo.template.head" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>

          <div v-if="currentSubTab === 6" style="height:460px;margin-bottom:22px">
            <v-ace-editor v-model:value="pageinfo.template.body" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="mt-6 flex justify-end">
        <button @click="submitForm" class="px-4 py-2 uppercase bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            <i class="fa-solid fa-floppy-disk"></i> Save
        </button>
      </div>
    </div>
  </template>
  
<script setup lang="ts">
  import { ref } from 'vue';
  import { VAceEditor } from 'vue3-ace-editor';
  import 'ace-builds/src-noconflict/mode-json';
  import 'ace-builds/src-noconflict/theme-chrome'; 
  import { SiteTemplate } from '@/entities';
  import { MessageBox, ValidateHelper } from '@/utility';
  import { useI18n } from 'vue-i18n';
  import { TemplateRepository } from '@/repositories';
  import { useRouter } from 'vue-router';

  const currentSubTab = ref(1);
  const router = useRouter();
  const validate = new ValidateHelper();
  const { t, locale } = useI18n();
  const siteRep = new TemplateRepository();

  var pageinfo = ref({
    template:new SiteTemplate()
  })

  // Form submission function
  const submitForm = async () => {
    console.log(pageinfo.value.template);

    if (validate.IsNullOrWhiteSpace(pageinfo.value.template.name)) {
      MessageBox.Alert(t('system.required') + ' : name');
      return;
    }
    
    if (validate.IsNullOrWhiteSpace(pageinfo.value.template.slug)) {
      MessageBox.Alert(t('system.required') + ' : slug');
      return;
    }
    
    let rst = await siteRep.CreateTemplate(pageinfo.value.template);
    if (rst.check) {
      MessageBox.Success(t('system.Saved'), () => {
        router.push('/resource/templates');
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  };
</script>
