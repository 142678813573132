export class Member {
    id: string;
    type: string;
    background: string;
    bio: string;
    job: string;
    nickName: string;
    photo: string;
    userName: string;
    email: string;
    emailConfirmed: boolean;
    phoneNumber: string | null;
    phoneNumberConfirmed: boolean;
    lockoutEnabled: boolean;
    twoFactorEnabled: boolean;
    accessFailedCount: number;
    lockoutEnd: Date | null;
    registDate: Date;
    lastUpdate: Date;
    status: string;
  
    constructor() {
      this.id = '';
      this.type = '';
      this.background = '';
      this.bio = '';
      this.job = '';
      this.nickName = '';
      this.photo = '';
      this.userName = '';
      this.email = '';
      this.emailConfirmed = false;
      this.phoneNumber = null;
      this.phoneNumberConfirmed = false;
      this.lockoutEnabled = false;
      this.twoFactorEnabled = false;
      this.accessFailedCount = 0;
      this.lockoutEnd = null;
      this.registDate = new Date();
      this.lastUpdate = new Date();
      this.status = '';
    }
  
    bindData(data: {
      id: string;
      type: string;
      background: string;
      bio: string;
      job: string;
      nickName: string;
      photo: string;
      userName: string;
      email: string;
      emailConfirmed: boolean;
      phoneNumber: string | null;
      phoneNumberConfirmed: boolean;
      lockoutEnabled: boolean;
      twoFactorEnabled: boolean;
      accessFailedCount: number;
      lockoutEnd: string | null;
      registDate: string;
      lastUpdate: string;
      status: string;
    }) {
      this.id = data.id;
      this.type = data.type;
      this.background = data.background;
      this.bio = data.bio;
      this.job = data.job;
      this.nickName = data.nickName;
      this.photo = data.photo;
      this.userName = data.userName;
      this.email = data.email;
      this.emailConfirmed = data.emailConfirmed;
      this.phoneNumber = data.phoneNumber;
      this.phoneNumberConfirmed = data.phoneNumberConfirmed;
      this.lockoutEnabled = data.lockoutEnabled;
      this.twoFactorEnabled = data.twoFactorEnabled;
      this.accessFailedCount = data.accessFailedCount;
      this.lockoutEnd = data.lockoutEnd ? new Date(data.lockoutEnd) : null;
      this.registDate = new Date(data.registDate);
      this.lastUpdate = new Date(data.lastUpdate);
      this.status = data.status;
    }
  }
  