import { createStore } from 'vuex';

export default createStore({
  state: {
    accessToken: '', // 인증토큰
    refreshToken: '', // 갱신토큰
    siteid:'',
    userinfo: {},
    loading: false, // 로딩 상태
  },
  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    refreshToken(state) {
      let token = state.refreshToken;
      if (token === null || token === undefined || String(token).trim() === '') {
        token = localStorage.getItem('refresh_token') ?? '';
      }
      return token;
    },
    userinfo(state) {
      return state.userinfo;
    },
    loading(state) {
      return state.loading;
    },
    siteid(state) {
      return state.siteid;
    }
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    clearTokens(state) {
      state.accessToken = '';
      state.refreshToken = '';
    },
    setUserInfo(state, user) {
      state.userinfo = user;
    },
    getUserInfo(state) {
      return state.userinfo;
    },
    clearUserInfo(state) {
      state.userinfo = {};
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setSiteId(state, id) {
      state.siteid = id;
    },
    getSiteId(state) {
      return state.siteid;
    }
  },
  actions: {
    saveTokens({ commit }, { accessToken, refreshToken }) {
      commit('setAccessToken', accessToken);
      commit('setRefreshToken', refreshToken);
    },
    saveUserInfo({ commit }, { userinfo }) {
      commit('setUserInfo', userinfo);
    },
    setLoading({ commit }, isLoading) {
      commit('setLoading', isLoading);
    },
    setSiteId({ commit }, id) {
      commit('setSiteId', id);
    }
  },
  modules: {},
});
