export class SiteTemplate {
    public id:string|null;
    public name:string;
    public slug:string;
    public thumbnail:string;
    public description:string;
    public header:string;
    public footer:string;
    public body:string;
    public scripts:string;
    public head:string;

    constructor() {
        this.id = null;
        this.name = "";
        this.slug = "";
        this.thumbnail = "";
        this.description = "";
        this.header = "";
        this.footer = "";
        this.body = "";
        this.scripts = "";
        this.head = "";
    }
}