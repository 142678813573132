<template>
        <div class="bg-white shadow-sm p-4">
        <div class="mb-4">
          <ul class="flex border-b">
            <li class="-mb-px mr-1">
              <a :class="{'border-l border-t border-r rounded-t text-blue-700': activeTab === 'current'}" class="bg-white inline-block py-2 px-4 font-semibold cursor-pointer bg-white" @click="activeTab = 'current'">My Membership</a>
            </li>
            <li class="mr-1">
              <a :class="{'border-l border-t border-r rounded-t text-blue-700': activeTab === 'membership'}" class="bg-white inline-block py-2 px-4 font-semibold cursor-pointer bg-white" @click="activeTab = 'membership'">Select Membership</a>
            </li>
          </ul>
        </div>
        <div v-if="activeTab === 'current'">
          <div class="mb-6">
            <h2 class="text-2xl font-bold mb-4">My Current Membership</h2>
            <p class="text-gray-700">Premium Membership</p>
            <p class="text-gray-500">Expires on: 2024-12-31</p>
          </div>
          <div>
            <h2 class="text-2xl font-bold mb-4">Purchase History</h2>
            <table class="min-w-full bg-white">
              <thead>
                <tr>
                  <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">Membership</th>
                  <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">Price</th>
                  <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="py-2 px-4 border-b border-gray-200">Premium Membership</td>
                  <td class="py-2 px-4 border-b border-gray-200">$9.99</td>
                  <td class="py-2 px-4 border-b border-gray-200">2024-01-01</td>
                </tr>
                <tr>
                  <td class="py-2 px-4 border-b border-gray-200">Free Membership</td>
                  <td class="py-2 px-4 border-b border-gray-200">$0.00</td>
                  <td class="py-2 px-4 border-b border-gray-200">2023-01-01</td>
                </tr>
                <!-- Add more purchase history entries as needed -->
              </tbody>
            </table>
          </div>
        </div>
            <section v-else class="text-gray-700 body-font overflow-hidden">
  <div class="container px-5 py-8 mx-auto flex flex-wrap">
    <div class="w-1/4" style="margin-top:118px">
      <div class="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
        <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start -mt-px">Fingerstache disrupt</p>
        <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">Franzen hashtag</p>
        <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">Tilde art party</p>
        <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">Banh mi cornhole</p>
        <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">Waistcoat squid hexagon</p>
        <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">Pinterest occupy authentic</p>
        <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">Brooklyn helvetica</p>
        <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">Long Feature Two</p>
        <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">Feature One</p>
      </div>
    </div>
    <div class="flex w-3/4 flex-wrap lg:border border-gray-300 rounded-lg">
      <div class="w-1/3 lg:mt-px mb-10 lg:mb-0 border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
        <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
          <h3 class="tracking-widest">START</h3>
          <h2 class="text-5xl text-gray-900 font-medium leading-none mb-4 mt-2">Free</h2>
          <span class="text-sm text-gray-600">Next 3 months</span>
        </div>
        <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">Schlitz single-origin</p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center">Feature</p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <div class="border-t border-gray-300 p-6 text-center rounded-bl-lg">
          <button class="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded">Get it
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
          <p class="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p>
        </div>
      </div>
      <div class="w-1/3 lg:-mt-px mb-10 lg:mb-0 border-2 rounded-lg border-indigo-500 relative">
        <span class="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
        <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
          <h3 class="tracking-widest">PRO</h3>
          <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">$38
            <span class="text-gray-600 text-base ml-1">/mo</span>
          </h2>
          <span class="text-sm text-gray-600">Charging $456 per year</span>
        </div>
        <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">Schlitz single-origin</p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center">Feature</p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <div class="p-6 text-center border-t border-gray-300">
          <button class="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded">Pay Now
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
          <p class="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p>
        </div>
      </div>
      <div class="w-1/3 lg:mt-px border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
        <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
          <h3 class="tracking-widest">BUSINESS</h3>
          <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">$54
            <span class="text-gray-600 text-base ml-1">/mo</span>
          </h2>
          <span class="text-sm text-gray-600">Charging $648 per year</span>
        </div>
        <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">Schlitz single-origin</p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center">Feature</p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <span class="w-5 h-5 inline-flex items-center justify-center text-blue-500 rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" class="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>
        <div class="p-6 text-center border-t border-gray-300">
          <button class="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded">Pay Now
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
          <p class="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p>
        </div>
      </div>
    </div>
  </div>
</section>
      </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  
  const activeTab = ref('current');
  </script>
  
  <style scoped>
  /* Add any additional styling here */
  </style>
  