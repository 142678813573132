import axios, { AxiosResponse } from 'axios';
import { ReturnValues } from '@/models';
import store from '@/store';
import { computed } from 'vue';

const access_token = computed(() => store.getters.accessToken);

const ApiHelper = {
  UploadAsync: async (url: string, jsonData?: FormData): Promise<any> => {
    let result:ReturnValues = new ReturnValues();

    try {
      const response = await axios.post(url, jsonData, { headers: {
        "Content-Type": 'multipart/form-data',
        'Authorization': `Bearer ${access_token.value}`
      }});
      if (response.status === 200) {
        result = response.data;
      } else {
        result.Error('Api Send Fail');
      }
    } catch (e:any) {
      if (e.response?.data !== null && e.response?.data !== undefined) {
        result.Error(e.response.data);
      } else {
        result.Error(e.message);
      }
    }

    return result;
  },

  PostAsync: async (url: string, jsonData?: any): Promise<any> => {
    let result:ReturnValues = new ReturnValues();

    console.log('Post : ', url);

    try {
      const response = await axios.post(url, jsonData, { headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }});

      if (response.status === 200 || response.status === 201) {
        result = response.data;
      } else {
        result.Error('Api Send Fail');
      }
    } catch (e:any) {
        if (e.response?.data !== null && e.response?.data !== undefined) {
          result.Error(e.response.data);
        } else {
          result.Error(e.message);
        }
    }

    return result;
  },

  PutAsync: async (url: string, jsonData?: any): Promise<any> => {
    let result: ReturnValues = new ReturnValues();
  
    console.log('Put : ', url);

    try {
      const response = await axios.put(url, jsonData, { headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }});
      if (response.status === 200) {
        result = response.data;
      } else {
        result.Error('Api Send Fail');
      }
    } catch (e: any) {
      if (e.response?.data !== null && e.response?.data !== undefined) {
        result.Error(e.response.data);
      } else {
        result.Error(e.message);
      }
    }
  
    return result;
  },


  DeleteAsync: async (url: string, jsonData?: any): Promise<any> => {
    let result: ReturnValues = new ReturnValues();

    try {
      const response = await axios.delete(url, { data:jsonData, headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }});
      if (response.status === 200) {
        result = response.data;
      } else {
        result.Error('Api Send Fail');
      }
    } catch (e: any) {
      if (e.response?.data !== null && e.response?.data !== undefined) {
        result.Error(e.response.data);
      } else {
        result.Error(e.message);
      }
    }
  
    return result;
  },

  GetAsync: async (url: string): Promise<any> => {
    let result:ReturnValues = new ReturnValues();

    try {
      const response = await axios.get(url, { headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }});
      if (response.status === 200) {
        result = response.data;
      } else {
        result.Error('Api Send Fail');
      }
    } catch (e:any) {
      if (e.response?.data !== null && e.response?.data !== undefined) {
        result.Error(e.response.data);
      } else {
        result.Error(e.message);
      }
    }

    return result;
  },
  Post: (url: string, jsonData: any, callback:Function): any => {
    let result:ReturnValues = new ReturnValues();

    try {
      axios.post(url, jsonData, { headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }}).then((rst:AxiosResponse<any,any>) =>{
        if (callback !== null && typeof callback === "function") {
            result.Success(1);
            result.data = rst.data;
            callback(result);
        }
      });
    } catch (e:any) {
        if (callback !== null && typeof callback === "function") {
            result.Error(e.message);
            callback(result);
        }
    }
  },

  Get: (url: string, callback:Function): any => {
    let result:ReturnValues = new ReturnValues();

    try {
      axios.get(url, { headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }}).then((rst:AxiosResponse<any,any>) => {
        if (callback !== null && typeof callback === "function") {
            result.Success(1);
            result.data = rst.data;
            callback(result);
        }
      });
    } catch (e:any) {
        if (callback !== null && typeof callback === "function") {
            result.Error(e.message);
            callback(result);
        }
    }
  },

  Put: (url: string, jsonData: any, callback:Function): any => {
    let result:ReturnValues = new ReturnValues();

    try {
      axios.put(url, jsonData, { headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }}).then((rst:AxiosResponse<any,any>) =>{
        if (callback !== null && typeof callback === "function") {
            result.Success(1);
            result.data = rst.data;
            callback(result);
        }
      });
    } catch (e:any) {
        if (callback !== null && typeof callback === "function") {
            result.Error(e.message);
            callback(result);
        }
    }
  },

  Delete: (url: string, jsonData: any, callback:Function): any => {
    let result:ReturnValues = new ReturnValues();

    try {
      axios.delete(url, { data:jsonData, headers: {
        "Content-Type": 'application/json',
        'Authorization': `Bearer ${access_token.value}`
      }}).then((rst:AxiosResponse<any,any>) =>{
        if (callback !== null && typeof callback === "function") {
            result.Success(1);
            result.data = rst.data;
            callback(result);
        }
      });
    } catch (e:any) {
        if (callback !== null && typeof callback === "function") {
            result.Error(e.message);
            callback(result);
        }
    }
  },
};

export default ApiHelper;
