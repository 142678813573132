import config from "@/config";
import { ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class TranslationRepository {
    private slug:string = "builder";

    async GetTranslations(languageCode:string):Promise<ReturnValues> {
        let url = `${config.apis.translation}/api/Translation/Site/${this.slug}/${languageCode}`;
        console.log('TranslationRepository GetTranslations : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetLanguages():Promise<ReturnValues> {
        let url = `${config.apis.translation}/api/Translation/Site/${this.slug}`;
        console.log('TranslationRepository GetLanguages : ', url);
        return await ApiHelper.GetAsync(url);
    }

}
