export class Site {
    public id:string|null;
    public name:string;
    public slug:string;
    public title:string;
    public description:string;
    public keywords:string;
    public thumbnail:string;
    public favicon:string;
    public templateId:string;
    public pageWidth:number;
    public mainColor:string;
    public subColor:string;
    public header:string;
    public footer:string;
    public scripts:string;
    public head:string;
    public isSSL:boolean;
    public body:string;
    public address1:string;
    public address2:string;
    public workTime:string;
    public phone:string;
    public email:string;
    public bizNo:string;
    public onlineCode:string;
    public isCustom:boolean;
    public customDomain:string;
    public companyId:string;

    public isAdmin:boolean;
    public isLogin:boolean;

    constructor() {
        this.id = null;
        this.name = "";
        this.slug = "";
        this.title = "";
        this.description = "";
        this.keywords = "";
        this.thumbnail = "";
        this.favicon = "";
        this.templateId = "";
        this.pageWidth = 1280;
        this.mainColor = "";
        this.subColor = "";
        this.header = "";
        this.footer = "";
        this.scripts = "";
        this.head = "";
        this.isSSL = false;
        this.address1 = "";
        this.address2 = "";
        this.workTime = "";
        this.phone = "";
        this.email = "";
        this.bizNo = "";
        this.onlineCode = "";
        this.isCustom = false;
        this.customDomain = "";
        this.companyId = "";
        this.isAdmin = false;
        this.isLogin = false;
        this.body = "";
    }
}