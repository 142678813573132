<template>
<div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
        <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-600 text-center mb-3 mt-5 font-bold">
            <h3>Reset Your Password</h3>
            </div>
            <form>
            <div class="relative w-full mb-3">
                <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                >
                Name
                </label>
                <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                />
            </div>

            <div class="relative w-full mb-3">
                <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                >
                Email
                </label>
                <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"
                />
            </div>

            <div class="text-center mt-6 flex">
                <div class="w-1/2 mr-2">
                    <router-link
                    class="bg-blueGray-500 block text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 pb-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    to="/"
                    >
                    Cancel
                    </router-link>
                </div>
                <div class="w-1/2">
                    <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button" @click="fnPasswordChange()"
                    >
                    Reset Password
                    </button>
                </div>

            </div>
            </form>
        </div>
        </div>
    </div>
    </div>
</div>
</template>

<script setup lang="ts">
    import { MessageBox } from '@/utility';

    const fnPasswordChange = () => {
        MessageBox.Confirm("정말로 비밀번호를 리셋하실 겁니까?", () => {
            console.log(1);
        });
    }
</script>
