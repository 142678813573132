<template>
    <div class="colorpicker">
      <input v-model="colorValue" type="color" @input="updateColor">
      <input v-model="colorValue" type="text" @input="updateColor">
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, watch } from 'vue';
  
  const colorValue = ref<string>('#ffffff');
  const showColorPicker = ref<boolean>(false);
  
  const updateColor = () => {
    showColorPicker.value = false;
  };
  
  watch(colorValue, (newColor) => {
    // 필요시 추가 동작을 여기에 작성하세요
  });
  </script>
  
  <style scoped>
  .colorpicker {
    cursor: pointer;
    overflow: hidden;
    padding: 2px 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center; /* 세로로 가운데 정렬 */
  }
  
  .colorpicker input[type=color] {
    width: 25px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    border: none; /* 경계선 제거 */
  }
  
  .colorpicker input[type=text] {
    border: none;
    background-color: transparent;
    height: 30px; /* 높이를 컬러 입력 필드와 맞춤 */
    padding: 0 5px; /* 수평 패딩을 추가하여 텍스트 입력 필드가 가운데 정렬되도록 함 */
  }
  </style>
  