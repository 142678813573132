<!-- src/components/Loading.vue -->
<template>
  <div class="loading-overlay" v-if="loading">
    <div class="spinner">Loading...</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['loading']),
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.spinner {
  color: #fff;
  font-size: 2em;
}
</style>
