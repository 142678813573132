<template>
    <p>wait</p>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import config from '@/config';

onMounted(() => {
    try {
        const settings: any = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: config.identity.baseUrl,
            client_id: config.identity.clientId,
            client_secret: config.identity.clientSecret,
            redirect_uri: `${config.identity.baseUrl}/signin-oidc`,
            silent_redirect_uri: `${config.identity.baseUrl}/silent-renew-oidc`,
            response_type: 'code',
            scope: config.identity.scope,
            post_logout_redirect_uri: `${config.identity.baseUrl}/signout-callback-oidc`,
            filterProtocolClaims: true,
            response_mode: 'query',
            usePkce: true 
        };

        console.log('settings : ', settings);

        const userManager = new UserManager(settings);
        userManager.signinRedirectCallback().then(function (user) {
            console.log("signin response success", user);
            document.location.href = '/';
        }).catch(function (err) {
            console.log('err : ', err.message);
            document.location.href = '/';
        });
    } catch (ex:any) {
        document.location.href = '/';
    }
});

</script>