import { UserManager, WebStorageStateStore, User } from 'oidc-client';
import config from '@/config';
import axios from 'axios';
import store from '@/store';
import { ReturnValues } from '@/models';

export default class AuthService {
    private userManager: UserManager;

    constructor() {
        const settings: any = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: config.identity.baseUrl,
            client_id: config.identity.clientId,
            client_secret: config.identity.clientSecret,
            redirect_uri: `${config.baseUrl}/signin-oidc`,
            response_type: 'code',
            scope: config.identity.scope,
            post_logout_redirect_uri: `${config.baseUrl}/signout-callback-oidc`,
            filterProtocolClaims: true,
            silent_redirect_uri: `${config.baseUrl}/silent-renew-oidc`,
            automaticSilentRenew: true,
            loadUserInfo: true,
        };

        this.userManager = new UserManager(settings);
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public async apiRegist(email:string, password:string, confirmpassword:string):Promise<ReturnValues> {
        let result = new ReturnValues();

        try {
            const response = await axios.post(`${config.identity.api}/api/Account/Regist`, {
                email: email,
                password: password,
                confirmPassword: confirmpassword
            });
            
            if (response.status === 200 || response.status === 201) {
                result.Success(1);
            } else {
                result.Error(response.data);
            }
        } catch (err:any) {
            result.Error(err.message);
        }

        return result;
    }

    public async apiLogin(email: string, password: string): Promise<ReturnValues> {
        let result = new ReturnValues();

        try {
            const response = await axios.post(`${config.identity.baseUrl}/connect/token`, new URLSearchParams({
                grant_type: 'password',
                client_id: config.identity.clientId,
                client_secret: config.identity.clientSecret,
                username: email,
                password: password,
                scope: config.identity.scope
            }));

            if (response.status === 200 || response.status === 201) {
                let user = new User({
                    id_token: '',
                    session_state: '',
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    token_type: response.data.token_type,
                    scope: response.data.scope,
                    profile: {} as any,
                    expires_at: response.data.expires_in,
                    state: null
                });

                await this.userManager.storeUser(user);
                store.dispatch('saveTokens', { accessToken: response.data.access_token, refreshToken: response.data.refresh_token });

                // 쿠키와 localStorage에 토큰 저장
                localStorage.setItem('refresh_token', response.data.refresh_token);

                result.Success(1);
                result.value = response.data;
            } else {
                result.Error(response.data);
            }
        } catch (err: any) {
            result.Error(err.message);
        }

        return result;
    }

    public async ExternalLogin(provider:string) {
        this.userManager.signinRedirect({ acr_values: 'idp:' + provider });
    }

    public refrash(): Promise<User | null> {
        //return this.userManager.signinSilent();
        return this.refreshAsync();
    }

    public async refreshAsync() {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        try {
            let parameter = new URLSearchParams({
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
                client_id: config.identity.clientId,
                client_secret: config.identity.clientSecret
            });

            const response = await axios.post(`${config.identity.baseUrl}/connect/token`, parameter,{headers:{'Content-Type':'application/x-www-form-urlencoded'}});

            if (!(response.status === 200 && response.data !== null))
            {
                console.error('Error refreshing token');
                return null;
            }

            const { access_token, refresh_token, expires_in } = response.data;
            store.dispatch('saveTokens', { accessToken: access_token, refreshToken: refresh_token });

            const user = await this.userManager.getUser();

            if (user) {
              user.access_token = access_token;
              user.refresh_token = refresh_token;
              user.expires_at = Number(expires_in);
              await this.userManager.storeUser(user);
            } else {
              // 사용자 정보가 없는 경우, 새로운 사용자 정보 가져오기
              await this.userManager.signinSilent();
            }

            return user;
        } catch (error) {
            console.error('Error refreshing token', error);
            throw error;
        }
    }

    public async logout(): Promise<void> {
        //return this.userManager.signoutRedirect();
        store.dispatch('saveTokens', { accessToken: '', refreshToken: '' });
        await this.userManager.removeUser();
        document.location.href = "/";
    }

    public getAccessToken(): Promise<string> {
        return this.userManager.getUser().then((data: any) => {
            return data.access_token;
        });
    }
}