<template>
  <div>
      <MultiFileUpload v-if="multiupload" :callback="fnMultiUploadCallback" />
      <Loading :isFullScreen="false" v-if="!IsBind" />
      <div :style="`visibility:${(IsBind) ? 'visible' : 'hidden'}`">
      <Editor
      apiKey="grw0w13jlmd6svqpvrpe4uh2ly8q34a2m8sqsylmny6bhiws"
      :key="componentinfo.key"
      :init="{
          height: 500,
          menubar: false,
          plugins: ['advlist','autolink','lists','link','image','wordcount','media','code'],
          toolbar:
          'undo redo | formatselect | bold italic backcolor | bullist numlist outdent indent | removeformat | image | media | code',
          images_upload_handler: uploadHandler,
          dropzone: {
          uploadMultiple: false,
          maxFiles: 10,
          acceptedFiles: 'image/*',
          success: handleDropzoneSuccess
          },
          relative_urls: false,
          document_base_url: config.apis.resource
      }"
      v-model="editorContent"
      :onInit="handleInit"
      ></Editor>
      </div>
  </div>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted, watch, defineProps } from 'vue';
  import Editor from '@tinymce/tinymce-vue'
  import { ReturnValues } from '@/models';
  import { ApiHelper, MessageBox } from '@/utility';
  import { MultiFileUpload } from '.';
  import config from '@/config';
  import { Loading } from '@/components';
  
  
  interface WebEdtorParameter {
      content:any,
      multiupload?:boolean|null|undefined,
      callback?:Function|null|undefined
  }
  const props = defineProps<WebEdtorParameter>();
  const editorContent = ref(props.content || '');
  const IsBind = ref(false);
  
  var componentinfo = ref({
      key:0
  });
  
  const handleEditorChange = (content: string) => {
      try {
          if (props.callback !== null && props.callback !== undefined && typeof props.callback === "function") {
              props.callback(content);
          }
      } catch (e: any) {
          console.log(e.message);
      }
  };
  
  const handleDropzoneSuccess = (images: any[]) => {
  try {
      const imageTags = images.map((image) => `<img src="${image.url}" alt="${image.name}" />`);
      editorContent.value += imageTags.join('');
  } catch (e: any) {
      console.log(e.message);
  }
  };
  
  const handleImageAppend = (images: string[]) => {
  try {
      const imageTags = images.map((image) => `<img src="${config.image+image}" alt="" />`);
      console.log('handleImageAppend : ', imageTags);
      editorContent.value += imageTags.join('');
  } catch (e: any) {
      console.log(e.message);
  }
  };
  
  const uploadHandler = (blobInfo: any): Promise<string> => {
  const formData = new FormData();
  formData.append('upload', blobInfo.blob(), blobInfo.filename());

  return new Promise((resolve, reject) => {
    ApiHelper.UploadAsync('/File/Upload', formData)
      .then((rst: ReturnValues) => {
        if (rst.check) {
          let newImages = rst.value.split(',');
          for (let i = 0; i < newImages.length; i++) {
            newImages[i] = config.image + newImages[i];
          }
          resolve(newImages.join(','));
        } else {
          reject(rst.message);
        }
      })
      .catch((error: any) => {
        reject(error.message || 'An unknown error occurred');
      });
  });
};

  
  const fnMultiUploadCallback = (newImages:string[]) => {
      console.log(fnMultiUploadCallback, newImages);
      handleImageAppend(newImages);
  };
  
  const handleInit = (editor: any) => {
      IsBind.value = true;
  };
  
  onMounted(() => {
      editorContent.value = props.content;
      componentinfo.value.key += 1;
  });
  
  watch(() => editorContent.value, (newContent) => {
      handleEditorChange(newContent);
  });
  </script>
  
<style lang="css">
.tox-tinymce { border-radius: 0 !important; }
</style>