<template>
<div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
        <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
        <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
            <h6 class="text-blueGray-500 text-sm font-bold">
                Sign up with
            </h6>
            </div>
            <div class="btn-wrapper text-center">
            <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button" @click="fnExternalLogin('GitHub')"
            >
                <img alt="..." class="w-5 mr-1" src="/img/github.svg" />
                Github
            </button>
            <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button" @click="fnExternalLogin('Google')"
            >
                <img alt="..." class="w-5 mr-1" src="/img/google.svg" />
                Google
            </button>
            <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button" @click="fnExternalLogin('Kakao')"
            >
                <img alt="..." class="w-5 mr-1" src="/img/kakao.svg" />
                Kakao
            </button>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
            <small>Or sign up with credentials</small>
            </div>
            <form>
            <div class="relative w-full mb-3">
                <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                >
                Email
                </label>
                <input
                type="email" v-model="pageinfo.email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"
                />
            </div>

            <div class="relative w-full mb-3">
                <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                >
                Password
                </label>
                <input
                type="password" v-model="pageinfo.password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Password"
                />
            </div>

            <div class="relative w-full mb-3">
                <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                >
                Confirm Password
                </label>
                <input
                type="password" v-model="pageinfo.confirmpassword"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Confirm Password"
                />
            </div>

            <div>
                <label class="inline-flex items-center cursor-pointer">
                <input
                    id="customCheckLogin"
                    type="checkbox" v-model="pageinfo.isprivacy"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                />
                <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    I agree with the
                    <a href="#" class="text-emerald-500" target="_blank">
                    Privacy Policy
                    </a>
                    and
                    <a href="#" class="text-emerald-500" target="_blank">
                    Term
                    </a>
                </span>
                </label>
            </div>

            <div class="text-center mt-6">
                <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button" @click="handleSubmit()"
                >
                Create Account
                </button>
            </div>
            </form>
        </div>
        </div>
    </div>
    </div>
</div>
</template>

<script setup lang="ts">
import { MessageBox } from '@/utility';
import { ref } from 'vue';
import { AuthService } from '@/services';

const auth = new AuthService();

var pageinfo = ref({
    email : "",
    password : "",
    confirmpassword : "",
    isprivacy:false
});

const handleSubmit = async () => {
    if (!pageinfo.value.email) {
        MessageBox.Alert("Email is required.");
        return;
    } else if (!/\S+@\S+\.\S+/.test(pageinfo.value.email)) {
        MessageBox.Alert("Email address is invalid.");
        return;
    }

    // Password 검증
    if (!pageinfo.value.password) {
        MessageBox.Alert("Password is required.");
        return;
    } else if (pageinfo.value.password.length < 6) {
        MessageBox.Alert("Password must be at least 6 characters long.");
        return;
    }

    // Confirm Password 검증
    if (pageinfo.value.password !== pageinfo.value.confirmpassword) {
        MessageBox.Alert("Passwords do not match.");
        return;
    }

    if (!pageinfo.value.isprivacy) {
        MessageBox.Alert("약관에 동의하셔야 이용 가능합니다.");
        return;
    }

    let rst = await auth.apiRegist(pageinfo.value.email, pageinfo.value.password, pageinfo.value.confirmpassword);
    if (rst.check) {
        MessageBox.Success("인증메일이 발송되었습니다. 이메일을 확인해 주세요.", () => {
            document.location.href = "/";
        });
    } else {
        MessageBox.Alert(rst.message);
    }
};

const fnExternalLogin = async (provider:string) => {
  await auth.ExternalLogin(provider);
}
</script>
