<template>
    <div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
      <div class="flex-auto p-4">
        <div class="flex flex-wrap">
          <ul>
            <li v-for="(site,index) in component_info.sites" :key="`site_dashboard_${index}`">{{ site.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </template>

<script setup lang="ts">
import { Site } from '@/entities';
import { SiteRepository } from '@/repositories/SiteRepository';
import { MessageBox } from '@/utility';
import { onMounted, ref } from 'vue';

const siteRep = new SiteRepository();

var component_info = ref({
  sites:[] as Site[]
})

onMounted(async () => {
  let rst = await siteRep.GetSites({ curPage : 1, pageSize : 100 });
  if (rst !== null && rst !== undefined && rst.check) {
    component_info.value.sites = rst.data as Site[];
  } else {
    MessageBox.Alert(rst.message);
  }
});

</script>
  