
<template>
  <div class="flex flex-wrap mr-3" :key="componentInfo.layerKey">
    <div class="w-full px-1">
      <div class="relative inline-flex align-middle w-full">
        <button 
          class="w-full text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 bg-teal-500"
          type="button" 
          ref="btnDropdownRef" 
          v-on:click="toggleDropdown()"
        >
          {{ componentInfo.selectlanguage.nativeName }}
        </button>
        <div 
          ref="popoverDropdownRef" 
          class="bg-teal-500 text-sm z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48" 
          v-bind:class="{ hidden: !dropdownPopoverShow, block: dropdownPopoverShow }"
        >
          <a 
            @click="fnLanguage(language)" 
            class="text-xs py-2 px-2 font-normal block w-full whitespace-no-wrap bg-transparent text-white" 
            v-for="(language, index) in componentInfo.languages" 
            :key="`language_${index}`"
          >
            {{ language.nativeName }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script setup lang="ts">
  import { ref,onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { createPopper, Instance } from '@popperjs/core';
  import { useRouter } from 'vue-router';
  import { TranslationRepository } from '@/repositories';
  import { LanguageItem } from '@/entities';
  
  const router = useRouter();
  const { t, locale } = useI18n();
  var selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ko');
  
  const btnDropdownRef = ref<HTMLElement | null>(null);
  const popoverDropdownRef = ref<HTMLElement | null>(null);
  const repository = new TranslationRepository();
  const dropdownPopoverShow = ref(false);
  let popperInstance: Instance | null = null;
  
  var componentInfo = ref({
    languages:[] as LanguageItem[],
    selectlanguage: new LanguageItem(),
    layerKey : 0
  });

  onMounted(async () => {
    var language = await repository.GetLanguages();
    if (language !== null && language !== undefined && language.check) {
      componentInfo.value.languages = language.data;
    }

    locale.value = selectedLanguage.value;
    componentInfo.value.selectlanguage = componentInfo.value.languages.filter(x => x.languageCode == locale.value)[0] as LanguageItem;
  });

  const toggleDropdown = () => {
    dropdownPopoverShow.value = !dropdownPopoverShow.value;
  
    if (dropdownPopoverShow.value) {
      createPopper(btnDropdownRef.value!, popoverDropdownRef.value!, {
        placement: 'bottom-start',
      });
    } else if (popperInstance) {
      popperInstance.destroy();
      popperInstance = null;
    }
  };
  
  const fnLanguage = (item:LanguageItem) => {
    dropdownPopoverShow.value = false;
    locale.value = item.languageCode;
    componentInfo.value.selectlanguage = item;
    localStorage.setItem('selectedLanguage', item.languageCode);
    componentInfo.value.layerKey += 1;
  };
  
  </script>