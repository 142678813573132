<template>
    <p>wait</p>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import config from '@/config';

onMounted(() => {
    const settings: any = {
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        authority: config.identity.baseUrl,
        client_id: config.identity.clientId,
        client_secret: config.identity.clientSecret,
        redirect_uri: `${config.baseUrl}/signin-oidc`,
        silent_redirect_uri: `${config.baseUrl}/silent-renew-oidc`,
        response_type: 'code',
        scope: config.identity.scope,
        post_logout_redirect_uri: `${config.baseUrl}/signout-callback-oidc`,
        filterProtocolClaims: true,
        response_mode: 'query',
        usePkce: true 
    };

    console.log(config);

    const userManager = new UserManager(settings);
    userManager.signoutCallback().then(function() {
        console.log("signout response success");
        window.location.href = '/';
    }).catch(function (err) {
        console.log('err : ', err);
    });
});

</script>