import { IPageBase } from "@/abstracts";
import config from "@/config";
import { SiteTemplate } from "@/entities";
import { PagingParameter, ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class TemplateRepository {
    async GetTemplates(paging:IPageBase):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/SiteTemplate/Templates?curPage=${paging.curPage}&pageSize=${paging.pageSize}`;
        console.log('TemplateRepository GetTemplates : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetTemplate(id:string):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/SiteTemplate/Template/${id}`;
        console.log('TemplateRepository GetTemplate : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async CreateTemplate(template:SiteTemplate):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/SiteTemplate/Template/Regist`;
        template.header = encodeURIComponent(template.header);
        template.footer = encodeURIComponent(template.footer);
        template.scripts = encodeURIComponent(template.scripts);
        template.head = encodeURIComponent(template.head);
        template.body = encodeURIComponent(template.body);
        console.log('TemplateRepository CreateTemplate : ', url);
        return await ApiHelper.PostAsync(url, template);
    }

    async UpdateTemplate(template:SiteTemplate):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/SiteTemplate/Template/Modify`;
        console.log('TemplateRepository UpdateTemplate : ', url);
        return await ApiHelper.PutAsync(url, template);
    }

    async DeleteTelplate(id:string):Promise<ReturnValues> {
        let url = `${config.apis.site}/api/SiteTemplate/Template/Erase/${id}`;
        console.log('TemplateRepository DeleteTelplate : ', url);
        return await ApiHelper.DeleteAsync(url);
    }
}
