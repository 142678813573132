<template>
    <div v-if="isOpen" class="image-manager">
        <div class="overlay" @click="closePopup"></div>
        <div class="popup">
            <h2>Image Gallery</h2>
            <div class="gallery">
                <div
                v-for="(image, index) in images"
                :key="index"
                class="image-item"
                @click="selectImage(image.webURL)"
                >
                    <img :src="image.webURL" alt="Image" />
                    <button @click.stop="removeImage(index)"><i class="fa-solid fa-square-minus"></i></button>
                </div>
            </div>
            <div style="position:relative;display:flex;justify-content: space-between;" class="w-full">
                <button @click="appendPopup" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded uppercase">Append</button>
                <input ref="btnRef" type="file" @change="addImage" style="display:none;" />
                <button @click="closePopup" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded uppercase">Close</button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, defineProps, defineEmits, onMounted } from 'vue';
    import { FileRepository } from '@/repositories';
    import { ResourceData } from '@/entities';
    import { MessageBox } from '@/utility';
    import { useI18n } from 'vue-i18n';

    const { t, locale } = useI18n();
    const fileRep = new FileRepository();
    const props = defineProps({
        isOpen: Boolean
    });
    const btnRef = ref<HTMLElement | null>(null);
    const emits = defineEmits(['close', 'imageSelected']);

    const images = ref<ResourceData[]>([
    // 초기 이미지 리스트
    ]);

    onMounted(async () => {
        var rst = await fileRep.GetImages();
        if (rst !== null && rst !== undefined) {
            if (rst.check && rst.data !== null && rst.data !== undefined) {
                images.value = rst.data;
            }
        }
    });

    const addImage = async (event: Event) => {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files[0]) {
            let rtn = await fileRep.FileUpload(target.files[0]);
            if (rtn !== null && rtn !== undefined) {
                if (rtn.check && rtn.data !== null && rtn.data !== undefined) {
                    let resource = rtn.data[0] as ResourceData;
                    images.value.push(resource);
                } else {
                    MessageBox.Alert(rtn.message);
                }
            }
        }
    };

    const removeImage = (index: number) => {
        MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
            let file = images.value[index];
            if (file) {
                let rtn = await fileRep.FileRemove(file.id);
                if (rtn !== null && rtn !== undefined)  {
                    if (rtn.check) {
                        images.value.splice(index, 1);
                    } else {
                        MessageBox.Alert(rtn.message);
                    }
                }
            }
        });
    };

    const selectImage = (image:string) => {
        emits('imageSelected', image);
        closePopup();
    };

    const closePopup = () => {
        emits('close');
    };

    const appendPopup = () => {
        btnRef.value?.click();
    };
</script>

<style scoped>
.image-manager {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.popup {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 1001;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    padding:0;
    width:100%;
}

.image-item {
    margin: 5px;
    position: relative;
    border:1px solid #EFEFEF;
    overflow:hidden;
    max-width:100px;
    max-height:100px;
}

.image-item img {
    width:auto;
    height:100%;
    cursor:pointer;
}

.image-item button {
    position: absolute;
    top: -3px;
    right: 0;
    background: transparent;
    color: black;
    border: none;
    cursor: pointer;
    width:14px;
    height:14px;
    padding:0;
}
</style>
