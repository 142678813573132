<template>
    <div class="w-full relative">
      <div class="w-full">
        <div class="w-3/12">
          <SiteList />
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
import { SiteList } from '@/components';
import { computed, onMounted, ref } from 'vue';
import { SiteRepository } from '@/repositories';
import store from '@/store';
import { Site } from '@/entities';

const site = computed(() => store.getters.siteid);
const siteRep = new SiteRepository();
console.log('Dashboard => siteid : ', site.value.id)

var pageinfo = ref({
  site:new Site()
});

onMounted(async() => {
  if (site.value !== null && site.value !== undefined && site.value.id !== null && site.value.id !== undefined) {
    let rst = await siteRep.GetSite(site.value.id);
    if (rst.check) {
      pageinfo.value.site = rst.data as Site;
    }
  }
});
</script>