<template>
  <footer class="block py-4" :key="`footer_${componentInfo.layerKey}`">
    <div class="container mx-auto px-4">
      <hr class="mb-4 border-b-1 border-blueGray-200" />
      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4">
          <div class="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left">
            Copyright © {{ componentInfo.year }}
            <a
              href="https://www.creative-tim.com?ref=vn-footer-admin"
              class="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1">
              Roslyn.dev
            </a>
          </div>
        </div>
        <div class="w-full md:w-8/12 px-4">
          <ul class="flex flex-wrap list-none md:justify-end justify-center">
            <li>
              <a
                href="https://www.creative-tim.com?ref=vn-footer-admin"
                class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3">
                {{ t("site.aboutus") }}
              </a>
            </li>
            <li>
              <a
                href="https://www.creative-tim.com/presentation?ref=vn-footer-admin"
                class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3">
                {{ t("site.privacy") }}
              </a>
            </li>
            <li>
              <a
                href="http://blog.creative-tim.com?ref=vn-footer-admin"
                class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3">
                {{ t("site.terms") }}
              </a>
            </li>
            <li>
              <a
                href="https://github.com/creativetimofficial/vue-notus/blob/main/LICENSE.md?ref=vn-footer-admin"
                class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3">
                {{ t("site.blog") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
  import { LanguageItem } from '@/entities';
  import { ref,onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { TranslationRepository } from '@/repositories';

  const { t, locale } = useI18n();
  const repository = new TranslationRepository();
  var selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ko');

  var componentInfo = ref({
    languages:[] as LanguageItem[],
    selectlanguage: new LanguageItem(),
    layerKey : 0,
    year : new Date().getFullYear()
  });

  onMounted(async () => {
    var language = await repository.GetLanguages();
    if (language !== null && language !== undefined && language.check) {
      componentInfo.value.languages = language.data;
    }

    locale.value = selectedLanguage.value;
    componentInfo.value.selectlanguage = componentInfo.value.languages.filter(x => x.languageCode == locale.value)[0] as LanguageItem;
  });
</script>
