<template>
    <div class="container mx-auto p-4">
      <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
        <div v-for="image in images" :key="image.id" class="bg-white p-4 rounded shadow-lg mb-2">
          <img :src="image.src" :alt="image.title" class="w-full h-48 object-cover rounded">
          <h2 class="text-lg font-semibold mt-2">{{ image.title }}</h2>
          <p class="text-gray-600">{{ image.description }}</p>
        </div>
      </div>
      <div class="flex justify-center mt-4">
        <button @click="uploadImage" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          이미지 업로드
        </button>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  
  interface Image {
    id: number;
    src: string;
    title: string;
    description: string;
  }
  
  const images = ref<Image[]>([
    { id: 1, src: 'https://via.placeholder.com/150', title: 'Image 1', description: 'This is image 1' },
    { id: 2, src: 'https://via.placeholder.com/150', title: 'Image 2', description: 'This is image 2' },
    { id: 3, src: 'https://via.placeholder.com/150', title: 'Image 3', description: 'This is image 3' },
    { id: 4, src: 'https://via.placeholder.com/150', title: 'Image 4', description: 'This is image 4' },
    { id: 5, src: 'https://via.placeholder.com/150', title: 'Image 5', description: 'This is image 5' },
    { id: 6, src: 'https://via.placeholder.com/150', title: 'Image 6', description: 'This is image 6' },
    { id: 7, src: 'https://via.placeholder.com/150', title: 'Image 7', description: 'This is image 7' },
    { id: 8, src: 'https://via.placeholder.com/150', title: 'Image 8', description: 'This is image 8' },
  ]);
  
  const uploadImage = () => {
    // 이미지 업로드 로직을 여기에 추가하세요
    console.log('이미지 업로드 버튼 클릭');
  };
  </script>
  
  <style scoped>
  /* 필요에 따라 추가 스타일을 정의할 수 있습니다 */
  </style>
  