<template>
    <div>
        <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
            <div class="block w-full overflow-x-auto">
            <!-- Projects table -->
            <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                <tr>
                    <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'" v-for="(title, index) in titles" :key="`title_${index}`">
                    {{ title }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in pageinfo.templates" :key="`template_${index}`">
                    <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <img :src="item.thumbnail ?? '#'" class="h-12 w-12 bg-white rounded-full border" alt="" />
                    <span class="ml-3 font-bold light">{{ item.name }}</span>
                    </th>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    Free
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <i class="fas fa-circle text-green-500 mr-2"></i> Available
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div class="flex items-center">
                        <span class="mr-2">60%</span>
                        <div class="relative w-full">
                        <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                        <div style="width: 60%;" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
                        </div>
                        </div>
                    </div>
                    </td>
                    <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                    >
                    <TableDropdown />
                    </td>
                </tr>

                </tbody>
            </table>
            </div>
            <hr/>
            <div class="py-2 flex justify-center items-center">
                <Pagination :total-count="pageinfo.totalCount" :cur-page="pageinfo.curpage" :callback="fnPaging"/>
            </div>
        </div>
    </div>
    <div>
        <button @click="addTemplate" class="px-4 py-2 uppercase bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            <i class="fa-solid fa-plus"></i> Create
        </button>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { TemplateRepository } from '@/repositories';
import { onMounted, ref } from 'vue';
import { SiteTemplate } from '@/entities';
import { Pagination,TableDropdown } from '@/components';

const router = useRouter();
const templateRep = new TemplateRepository();

var pageinfo = ref({
    templates:[] as SiteTemplate[],
    totalCount:0,
    curpage:1
});

const titles = [ 'TemplateName','Budget','Status','USING','' ]

onMounted(async () => {
    let rst = await templateRep.GetTemplates({ curPage : 1, pageSize : 10 });
    if (rst.check) {
        pageinfo.value.totalCount = rst.code;
        pageinfo.value.templates = rst.data as SiteTemplate[];
    }
});

const addTemplate = () => {
    router.push('/resource/template/add');
};

const fnPaging = (pageNum:number) => {
    console.log('fnPaging : ', pageNum);
};
</script>