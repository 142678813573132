<template>
  <div class="bg-white shadow-sm p-4" :key="`siteconfig_${pageinfo.key}`">
      <h1 class="text-2xl font-bold text-blue-600 mb-6">Site Configuration
        <span class="float-right text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-indigo-600 bg-indigo-200 uppercase last:mr-0 mr-1">
        FREE
        </span>
      </h1>



      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal pointer" v-on:click="toggleTabs(1)" v-bind:class="{'text-lightBlue-600 bg-white': currentTab !== 1, 'text-white bg-lightBlue-600': currentTab === 1}">
            Main
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal pointer" v-on:click="toggleTabs(2)" v-bind:class="{'text-lightBlue-600 bg-white': currentTab !== 2, 'text-white bg-lightBlue-600': currentTab === 2}">
            Details
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal pointer" v-on:click="toggleTabs(3)" v-bind:class="{'text-lightBlue-600 bg-white': currentTab !== 3, 'text-white bg-lightBlue-600': currentTab === 3}">
            Others
          </a>
        </li>
      </ul>
  
      <!-- Main Tab -->
      <div v-if="currentTab === 1">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Name -->
          <div>
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <input v-model="pageinfo.site.name" type="text" id="name" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter website name">
          </div>
  
          <!-- Slug -->
          <div class="w-full">
            <label for="slug" class="block text-sm font-medium text-gray-700">Slug</label>
            <div class="w-full justify-center">
            <input v-model="pageinfo.site.slug" type="text" id="slug" style="width:60%" class="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm mr-1" placeholder="Enter website slug">
            
            <select style="width:30%" class="p-2 border border-gray-300 rounded-md shadow-sm p-3" v-model="pageinfo.site.templateId">
              <option value="">Select Template</option>
              <option :value="template.id" v-for="(template,index) in pageinfo.templates" :key="`template_selectbox_${index}`">{{ template.name }}</option>
            </select>
            </div>
          </div>
        
          <!-- Title -->
          <div>
            <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
            <input v-model="pageinfo.site.title" type="text" id="title" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter website title">
          </div>
  
          <!-- Template ID -->
          <div>
            <label for="customDomain" class="block text-sm font-medium text-gray-700">Custom Domain</label>
            <input v-model="pageinfo.site.customDomain" type="text" id="customDomain" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter custom domain">
          </div>
  
          <!-- SSL -->
          <div>
            <label for="isSSL" class="flex items-center text-sm font-medium text-gray-700">
              <Toggle v-model="pageinfo.site.isSSL" :disabled="false"/>
              <span class="p-1 text-md text-gray-500">Use SSL</span>
            </label>
          </div>
  
          <!-- Admin -->
          <div>
            <label for="isAdmin" class="flex items-center text-sm font-medium text-gray-700">
              <Toggle v-model="pageinfo.site.isAdmin" :disabled="false" />
              <span class="p-1 text-md text-gray-500">Admin Access</span>
            </label>
          </div>
  
          <!-- Login -->
          <div>
            <label for="isLogin" class="flex items-center text-sm font-medium text-gray-700">
              <Toggle v-model="pageinfo.site.isLogin" :disabled="false" />
            <span class="p-1 text-md text-gray-500">Login Required</span>
            </label>
          </div>
  
          <!-- Custom -->
          <div>
            <label for="isCustom" class="flex items-center text-sm font-medium text-gray-700">
              <Toggle v-model="pageinfo.site.isCustom" :disabled="false" />
            <span class="p-1 text-md text-gray-500">Custom Domain</span>
            </label>
          </div>

        </div>
      </div>
  
      <!-- Details Tab -->
      <div v-if="currentTab === 2">
        <div class="flex border-b border-gray-200">
            <button @click="currentSubTab = 1" :class="{'bg-lightBlue-600 text-white': currentSubTab === 1, 'text-gray-200': currentSubTab !== 1}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Header</button>
            <button @click="currentSubTab = 2" :class="{'bg-lightBlue-600 text-white': currentSubTab === 2, 'text-gray-200': currentSubTab !== 2}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Footer</button>
            <button @click="currentSubTab = 3" :class="{'bg-lightBlue-600 text-white': currentSubTab === 3, 'text-gray-200': currentSubTab !== 3}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Description</button>
            <button @click="currentSubTab = 4" :class="{'bg-lightBlue-600 text-white': currentSubTab === 4, 'text-gray-200': currentSubTab !== 4}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Scripts</button>
            <button @click="currentSubTab = 5" :class="{'bg-lightBlue-600 text-white': currentSubTab === 5, 'text-gray-200': currentSubTab !== 5}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Head</button>
            <button @click="currentSubTab = 6" :class="{'bg-lightBlue-600 text-white': currentSubTab === 6, 'text-gray-200': currentSubTab !== 6}" class="px-4 py-2 text-sm font-medium border-b-2 border-transparent hover:border-blue-600 focus:outline-none">Body</button>
        </div>

        <div class="grid grid-cols-1">
          <!-- Header -->
          <div v-if="currentSubTab === 1">
            <v-ace-editor v-model:value="pageinfo.site.header" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>
  
          <!-- Footer -->
          <div v-if="currentSubTab === 2">
            <v-ace-editor v-model:value="pageinfo.site.footer" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>
  
          <!-- Description -->
          <div v-if="currentSubTab === 3">
            <v-ace-editor v-model:value="pageinfo.site.description" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>
  
          <div v-if="currentSubTab === 4">
            <v-ace-editor v-model:value="pageinfo.site.scripts" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>

          <!-- Styles -->
          <div v-if="currentSubTab === 5">
            <v-ace-editor v-model:value="pageinfo.site.head" lang="text" theme="chrome" class="mt-1 w-full border border-gray-300" style="height: 460px;border-radius:0;" />
          </div>

          <div v-if="currentSubTab === 6" style="height:460px;margin-bottom:22px">
            <WebEditor :content="pageinfo.site.body" :multiupload="false" :key="`sitebody_${pageinfo.key}`" :callback="handleUpdate" />
          </div>
        </div>
      </div>
  
      <!-- Others Tab -->
      <div v-if="currentTab === 3">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Page Width -->
          <div>
            <label for="pageWidth" class="block text-sm font-medium text-gray-700">Page Width</label>
            <input v-model.number="pageinfo.site.pageWidth" type="range" id="pageWidth" min="800" max="2000" step="10" class="mt-1 w-full" @input="updatePageWidth">
            <div class="flex justify-between text-sm text-gray-500">
              <span>800px</span>
              <span>{{ pageinfo.site.pageWidth }}px</span>
              <span>2000px</span>
            </div>
          </div>
  
          <!-- Main Color -->
          <div class="grid grid-cols-2 gap-6">
            <div class="border border-1 px-2 py-1 rounded-md">
                <label class="block text-sm font-medium text-gray-700 pb-4">Main Color</label>
                <ColorPicker v-model="pageinfo.site.mainColor" />
            </div>
            <div class="border border-1 px-2 py-1 rounded-md">
                <label class="block text-sm font-medium text-gray-700 pb-4">Sub Color</label>
                <ColorPicker v-model="pageinfo.site.subColor" />
            </div>
          </div>
  
          <!-- Favicon -->
          <div>
            <label for="favicon" class="block text-sm font-medium text-gray-700">Favicon</label>
            <input v-model="pageinfo.site.favicon" type="text" id="favicon" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter favicon URL">
          </div>
  
          <!-- Thumbnail -->
          <div>
            <label for="thumbnail" class="block text-sm font-medium text-gray-700">Thumbnail</label>
            <input v-model="pageinfo.site.thumbnail" type="text" id="thumbnail" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter thumbnail URL">
          </div>
  
          <!-- Address 1 -->
          <div>
            <label for="address1" class="block text-sm font-medium text-gray-700">Address 1</label>
            <input v-model="pageinfo.site.address1" type="text" id="address1" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter address line 1">
          </div>
  
          <!-- Address 2 -->
          <div>
            <label for="address2" class="block text-sm font-medium text-gray-700">Address 2</label>
            <input v-model="pageinfo.site.address2" type="text" id="address2" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter address line 2">
          </div>
  
          <!-- Work Time -->
          <div>
            <label for="workTime" class="block text-sm font-medium text-gray-700">Work Time</label>
            <input v-model="pageinfo.site.workTime" type="text" id="workTime" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter work time">
          </div>
  
          <!-- Phone -->
          <div>
            <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
            <input v-model="pageinfo.site.phone" type="text" id="phone" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter phone number">
          </div>
  
          <!-- Email -->
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <input v-model="pageinfo.site.email" type="email" id="email" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter email address">
          </div>
  
          <!-- Business Number -->
          <div>
            <label for="bizNo" class="block text-sm font-medium text-gray-700">Business Number</label>
            <input v-model="pageinfo.site.bizNo" type="text" id="bizNo" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter business number">
          </div>
  
          <!-- Online Code -->
          <div>
            <label for="onlineCode" class="block text-sm font-medium text-gray-700">Online Code</label>
            <input v-model="pageinfo.site.onlineCode" type="text" id="onlineCode" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Enter online code">
          </div>
        </div>
      </div>
  
      <!-- Submit Button -->
      <div class="mt-6 flex justify-end">
        <button @click="submitForm" class="px-4 py-2 uppercase bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            <i class="fa-solid fa-floppy-disk"></i> Save
        </button>
      </div>
    </div>
  </template>
  
<script setup lang="ts">
  import { computed, onMounted, ref,watch } from 'vue';
  import { VAceEditor } from 'vue3-ace-editor';
  import 'ace-builds/src-noconflict/mode-json';
  import 'ace-builds/src-noconflict/theme-chrome'; 
  import { ColorPicker } from '@/components';
  import Toggle from '@vueform/toggle'
  import { Site, SiteTemplate } from '@/entities';
  import { WebEditor } from '@/components/Editor';
  import { MessageBox, ValidateHelper } from '@/utility';
  import { useI18n } from 'vue-i18n';
  import { SiteRepository,TemplateRepository } from '@/repositories';
  import store from '@/store';
  import { ReturnValues } from '@/models';

  const currentTab = ref(1);
  const currentSubTab = ref(1);
  const validate = new ValidateHelper();
  const { t, locale } = useI18n();
  const siteRep = new SiteRepository();
  const templateRep = new TemplateRepository();
  const site = computed(() => store.getters.siteid);

  var pageinfo = ref({
    key:1,
    site:new Site(),
    templates:[] as SiteTemplate[]
  });

  onMounted(async () => {
    let rst = await templateRep.GetTemplates({ curPage : 1, pageSize : 20 });
    if (rst.check) {
      pageinfo.value.templates = rst.data as SiteTemplate[];
    }

    if (site.value !== null && site.value !== undefined && site.value.id !== null && site.value.id !== undefined) {
      let sitecheck = await siteRep.GetSite(site.value.id);
      if (sitecheck.check) {
        pageinfo.value.site = sitecheck.data as Site;
      }
    }
  });

  watch(site, async () => {
    if (site.value !== null && site.value !== undefined && site.value.id !== null && site.value.id !== undefined) {
      let sitecheck = await siteRep.GetSite(site.value.id);
      if (sitecheck.check) {
        pageinfo.value.site = sitecheck.data as Site;
        pageinfo.value.key += 1;
      }
    } else {
      pageinfo.value.site = new Site();
    }
  });

  // Handle page width slider input
  const updatePageWidth = () => {
    pageinfo.value.site.pageWidth = Math.round(pageinfo.value.site.pageWidth / 10) * 10;
  };
  
  // Form submission function
  const submitForm = async () => {
    if (validate.IsNullOrWhiteSpace(pageinfo.value.site.name)) {
      MessageBox.Alert(t('system.required') + ' : name');
      return;
    }
    
    if (validate.IsNullOrWhiteSpace(pageinfo.value.site.slug)) {
      MessageBox.Alert(t('system.required') + ' : slug');
      return;
    }
    
    let rst = new ReturnValues();
    if (pageinfo.value.site.id !== null && pageinfo.value.site !== undefined && String(pageinfo.value.site).trim() !== '') {
      rst = await siteRep.UpdateSite(pageinfo.value.site);
    } else {
      rst = await siteRep.CreateSite(pageinfo.value.site);
    }

    if (rst.check) {
      pageinfo.value.site = rst.data as Site;
      MessageBox.Success(t('system.Saved'), () => {
        store.dispatch('setSiteId', { id: pageinfo.value.site.id });
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  }


  const toggleTabs = (num:number) => {
    currentTab.value = num;
  }

  const handleUpdate = (content:string) => {
    pageinfo.value.site.body = content;
  };

</script>

<style src="@vueform/toggle/themes/default.css"></style>
