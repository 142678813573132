
<template>
<div class="flex flex-wrap" :key="`SiteDropdown_${pageinfo.key}`">
    <div class="w-full px-1">
    <div class="relative inline-flex align-middle w-full">
        <button class="w-full text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-400" type="button" ref="btnDropdownRef" v-on:click="toggleDropdown()">
          {{ pageinfo.buttonText }}
        </button>
        <div ref="popoverDropdownRef" class="bg-lightBlue-500 text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48" v-bind:class="{hidden: !dropdownPopoverShow,block: dropdownPopoverShow,}">
        <a @click="fnMenuLink(site)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white pointer" v-for="(site,index) in pageinfo.sites" :key="`sitemenu_${index}`">
            {{ site.name }}
        </a>
        <div v-if="pageinfo.sites.length > 0" class="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25"></div>
        <a @click="fnCreateLink()" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white pointer">
            Create New Site
        </a>
        </div>
    </div>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { createPopper, Instance } from '@popperjs/core';
import { useRouter } from 'vue-router';
import { SiteRepository } from '@/repositories/SiteRepository';
import { Site } from '@/entities';
import store from '@/store';

const router = useRouter();
const siteRep = new SiteRepository();

const btnDropdownRef = ref<HTMLElement | null>(null);
const popoverDropdownRef = ref<HTMLElement | null>(null);
const dropdownPopoverShow = ref(false);
let popperInstance: Instance | null = null;


var pageinfo = ref({
  key:1,
  buttonText:'Sites',
  sites : [] as Site[]
});

onMounted(async () => {
  let rst = await siteRep.GetRecentSites(10);
  if (rst.check) {
    pageinfo.value.sites = rst.data as Site[];
  }
});

const toggleDropdown = () => {
  dropdownPopoverShow.value = !dropdownPopoverShow.value;

  if (dropdownPopoverShow.value) {
    createPopper(btnDropdownRef.value!, popoverDropdownRef.value!, {
      placement: 'bottom-start',
    });
  } else if (popperInstance) {
    popperInstance.destroy();
    popperInstance = null;
  }
};

const fnMenuLink = (site:Site) => {
  store.dispatch('setSiteId', { id: site.id });
  pageinfo.value.buttonText = site.name;
  dropdownPopoverShow.value = false;
  router.push('/site/dashboard');
};

const fnCreateLink = () => {
  store.dispatch('setSiteId', { id: null });
  pageinfo.value.buttonText = 'Sites';
  router.push('/site/settings');
  dropdownPopoverShow.value = false;
};
</script>