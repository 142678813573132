import config from "@/config";
import { Member } from "@/entities";
import { ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class MemberRepository {
    private slug:string = "builder";

    async GetUser():Promise<ReturnValues> {
        let url = `${config.apis.member}/api/User`;
        console.log('MemberRepository GetUser : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async UpdateMemberInfo(member:Member):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/User`;
        console.log('MemberRepository UpdateMemberInfo : ', url);
        let jsonData = {
            background: member.background,
            bio: member.bio,
            job: member.job,
            nickName: member.nickName,
            photo: member.photo
        };
        return await ApiHelper.PutAsync(url, jsonData);
    }
}
