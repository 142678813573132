export class LoginInputModel {
    public rememberLogin:boolean;
    public returnUrl:string;
    public username:string;
    public password:string;

    constructor() {
        this.rememberLogin = false;
        this.returnUrl = "";
        this.username = "";
        this.password = "";
    }
}