export class LanguageItem {
    public languageCode:string;
    public languageName:string;
    public nativeName:string;

    constructor() {
        this.languageCode = "";
        this.languageName = "";
        this.nativeName = "";
    }
}