export class ValidateHelper {
    IsNullOrWhiteSpace(value:any):boolean {
        if (value !== null && value !== undefined && String(value).trim() !== '') {
            return false;
        } else {
            return true;
        }
    }


}