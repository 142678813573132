<template>
  <div>
    <div class="items-center flex mr-2">
      <a
        class="w-10 h-10 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        style="cursor:pointer"
        ref="btnDropdownRef"
        @click="toggleDropdown"
      >
      <i class="fas fa-bell text-lightBlue-600"></i>
    </a>
    </div>

    <div
      ref="popoverDropdownRef"
      :class="{
        'hidden': !dropdownPopoverShow,
        'block': dropdownPopoverShow,
      }"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
    >
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        SSL이 발급되었습니다.
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        웹사이트가 수정되었습니다.
      </a>
      <div class="h-0 my-2 border border-solid border-blueGray-100"></div>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Clear All Notifications
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { createPopper, Instance } from '@popperjs/core';

const btnDropdownRef = ref<HTMLElement | null>(null);
const popoverDropdownRef = ref<HTMLElement | null>(null);
const dropdownPopoverShow = ref(false);
let popperInstance: Instance | null = null;

const toggleDropdown = (event: MouseEvent) => {
  event.preventDefault();
  dropdownPopoverShow.value = !dropdownPopoverShow.value;

  if (dropdownPopoverShow.value) {
    createPopper(btnDropdownRef.value!, popoverDropdownRef.value!, {
      placement: 'bottom-start',
    });
  } else if (popperInstance) {
    popperInstance.destroy();
    popperInstance = null;
  }
};
</script>
