<template>
    <div>
      <a
        class="text-blueGray-500 py-1 px-3"
        href="#pablo"
        ref="btnDropdownRef"
        @click="toggleDropdown"
      >
        <i class="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        :class="{ hidden: !dropdownPopoverShow, block: dropdownPopoverShow }"
      >
        <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Action
        </a>
        <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Another action
        </a>
        <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Something else here
        </a>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import { createPopper } from '@popperjs/core';
  
  const dropdownPopoverShow = ref(false);
  const btnDropdownRef = ref<HTMLElement | null>(null);
  const popoverDropdownRef = ref<HTMLElement | null>(null);
  
  const toggleDropdown = (event: Event) => {
    event.preventDefault();
    if (dropdownPopoverShow.value) {
      dropdownPopoverShow.value = false;
    } else {
      dropdownPopoverShow.value = true;
      if (btnDropdownRef.value && popoverDropdownRef.value) {
        createPopper(btnDropdownRef.value, popoverDropdownRef.value, {
          placement: 'bottom-start',
        });
      }
    }
  };
  </script>
  
  <style scoped>
  /* 필요에 따라 스타일을 추가할 수 있습니다 */
  </style>
  