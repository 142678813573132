import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { LoginView,MainView,SiteConfig,Dashboard,UsersView,BoardsView,ArticlesView,RegistView } from '@/views';
import { AdminArticlesView,AdminBoardsView,AdminDashboard,AdminSiteConfig,AdminUsersView,ResetPassword } from '@/views';
import { Gallery,Templates,Plugins,ArticleDocument,Profile,PasswordChangeView,MemberShip,AccountSettings } from '@/views';
import { AuthCheck,AuthClear,AuthRefresh,TemplateConfig } from '@/views';
import { AuthLayout,AdminLayout } from '@/layouts';

const routes: Array<RouteRecordRaw> = [
  { path: '/signin-oidc',name: 'signin',component: AuthCheck },
  { path: '/signout-callback-oidc',name: 'signout',component: AuthClear },
  { path: '/silent-renew-oidc',name: 'signrefresh',component: AuthRefresh },
  { path: '/', name: 'home',component: AuthLayout, children: [ 
    { path:'/', component:LoginView },
    { path:'/regist', component:RegistView },
    { path:'/passwordreset', component:ResetPassword }
  ] },
  { path: '/main', name: 'main',component: AdminLayout, children: [ { path:'/main', component:MainView } ] },
  { path: '/site', name: 'sites',component: AdminLayout, children: [ 
    { path:'/site/dashboard', component:Dashboard },
    { path:'/site/users', component:UsersView },
    { path:'/site/boards', component:BoardsView },
    { path:'/site/articles', component:ArticlesView },
    { path:'/site/settings', component:SiteConfig }
  ] },
  { path: '/admin', name: 'admins',component: AdminLayout, children: [ 
    { path:'/admin/dashboard', component:AdminDashboard },
    { path:'/admin/users', component:AdminUsersView },
    { path:'/admin/boards', component:AdminBoardsView },
    { path:'/admin/articles', component:AdminArticlesView },
    { path:'/admin/settings', component:AdminSiteConfig }
  ] },
  { path: '/resource', name: 'resources',component: AdminLayout, children: [ 
    { path:'/resource/templates', component:Templates },
    { path:'/resource/template/add', component:TemplateConfig },
    { path:'/resource/plugins', component:Plugins },
    { path:'/resource/gallery', component:Gallery }
  ] },
  { path: '/document', name: 'documents',component: AdminLayout, children: [ 
    { path:'/document/articles', component:ArticleDocument },
  ] },
  { path: '/account', name: 'accounts',component: AdminLayout, children: [ 
    { path:'/account/profile', component:Profile },
    { path:'/account/password', component:PasswordChangeView },
    { path:'/account/membership', component:MemberShip },
    { path:'/account/settings', component:AccountSettings },
  ] },
]

const router = createRouter({
  history: createWebHistory(""),
  routes
})

export default router
