<template>
<img :src="profileImageUrl" alt="Profile Photo" :style="((width !== null) ? `width:${width}px;` : ``) + ((height !== null) ? `height:${height}px;` : ``)" />
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';


// Define the props for the component
const props = defineProps<{
    url: string | null;
    email: string;
    width:number | null;
    height:number | null;
}>();

// Function to generate Gravatar URL
function getGravatarUrl(email: string, width:number|null, height:number|null): string {
    if (email !== null && email !== undefined && String(email).trim() !== '') {
        const trimmedEmail = email.trim().toLowerCase();
        const hash = md5(trimmedEmail);
        return `https://www.gravatar.com/avatar/${hash}`;
    } else {
        if (width !== null && height !== null) {
            return `https://via.placeholder.com/${width}x${height}`;
        } else if (width !== null && height === null) {
            return `https://via.placeholder.com/${width}`;
        } else {
            return "https://via.placeholder.com/150";
        }
    }
}

// Compute the profile image URL
const profileImageUrl = computed(() => {
if (props.url) {
    return props.url;
} else {
    return getGravatarUrl(props.email, props.width, props.height);
}
});

// Simple MD5 hash function (for Gravatar URL)
const md5 = async (str: string):Promise<string> => {
    if (str !== null && str !== undefined && String(str).trim() !== '') {
        try {
            let hash = '';
            const hexChars = '0123456789abcdef';
            const md5Array = await crypto.subtle.digest('MD5', new TextEncoder().encode(str));

            for (let byte of new Uint8Array(md5Array)) {
                hash += hexChars[(byte >> 4) & 0x0f];
                hash += hexChars[byte & 0x0f];
            }

            return hash;
        } catch (e:any) {
            return "";
        }
    } else {
        return "";
    }
}
</script>
