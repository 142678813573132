export class ReturnValues {
    public check:boolean;
    public code:number;
    public value:string;
    public message:string;
    public data:any;

    constructor() {
        this.check = false;
        this.code = -1;
        this.value = "";
        this.message = "";
        this.data = null;
    }

    Success(num:number) {
        this.check = true;
        this.code = num;
    }

    Error(msg:string) {
        this.check = false;
        this.code = -1;
        this.message = msg;
    }
}