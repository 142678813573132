<template>
<div class="py-2" :key="`Paging_${component_info.key}`">
  <nav class="block">
    <ul class="flex pl-0 rounded list-none flex-wrap">
      <li>
        <a @click="fnPrevious" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-lightBlue-500 pointer">
          <i class="fas fa-chevron-left -ml-px"></i>
        </a>
      </li>
      <li v-for="(pageno,index) in component_info.items" :key="`pagination_${index}`">
        <a @click="fnPageMove(pageno.page)" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-lightBlue-500 pointer">
          {{ pageno.page }}
        </a>
      </li>
      <li>
        <a @click="fnNext" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-lightBlue-500 pointer">
          <i class="fas fa-chevron-right -mr-px"></i>
        </a>
      </li>
    </ul>
  </nav>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref,defineProps } from 'vue';

interface PagingParameter {
  totalCount:number,
  curPage:number,
  callback:Function
}

const property = defineProps<PagingParameter>();

var component_info = ref({
  key:0,
  TotalCount:0,
  PageSize:10,
  CurPage:1,
  EndPage:1,
  StartPage:1,
  FirstPage:1,
  LastPage:1,
  Callback:null as any,
  items: [] as { page: number }[],
});

onMounted(() => {
  component_info.value.TotalCount = property.totalCount;
  component_info.value.CurPage = property.curPage;
  component_info.value.Callback = property.callback;
  fnSet();
});

const fnSet = () => {
    if (component_info.value.TotalCount > component_info.value.PageSize) {
      component_info.value.EndPage = Math.floor(component_info.value.TotalCount / component_info.value.PageSize);
      if ((component_info.value.TotalCount % component_info.value.PageSize) > 0) {
          component_info.value.EndPage += 1;
      }
      component_info.value.FirstPage = (component_info.value.CurPage > component_info.value.PageSize && (component_info.value.CurPage % component_info.value.PageSize) > 0) ? (Math.floor(component_info.value.CurPage / component_info.value.PageSize) * component_info.value.PageSize) + 1 : (Math.floor((component_info.value.CurPage - 1) / component_info.value.PageSize) * component_info.value.PageSize) + 1;
      component_info.value.LastPage = component_info.value.FirstPage + (component_info.value.PageSize - 1);
      if (component_info.value.LastPage > component_info.value.EndPage) {
          component_info.value.LastPage = component_info.value.EndPage;
      }
      component_info.value.items = [];
      for(let i = component_info.value.FirstPage; i <= component_info.value.LastPage; i++) {
          component_info.value.items.push({ page: i });
      }
    } else {
      component_info.value.EndPage = 1;
      component_info.value.FirstPage = 1;
      component_info.value.LastPage = 1;
      component_info.value.items = [ { page: 1 } ];
    }

    component_info.value.key += 1;
};

const fnPageMove = (num:number) => {
  if (component_info.value.Callback !== null && component_info.value.Callback !== undefined && typeof component_info.value.Callback === "function") {
    component_info.value.CurPage = num;
    component_info.value.key += 1;
    component_info.value.Callback(component_info.value.CurPage);
  }
};

const fnPrevious = () => {
    if (component_info.value.FirstPage > component_info.value.PageSize) {
        component_info.value.CurPage = component_info.value.FirstPage - 1;
        fnSet();
        component_info.value.key += 1;
        if (component_info.value.Callback !== null && component_info.value.Callback !== undefined && typeof component_info.value.Callback === "function") {
          component_info.value.Callback(component_info.value.CurPage);
        }
    }
};

const fnNext = () => {
    if (component_info.value.EndPage > component_info.value.LastPage) {
        component_info.value.CurPage = component_info.value.LastPage + 1;
        fnSet();
        component_info.value.key += 1;
        if (component_info.value.Callback !== null && component_info.value.Callback !== undefined && typeof component_info.value.Callback === "function") {
          component_info.value.Callback(component_info.value.CurPage);
        }
    }
};
</script>