<template>
<div class="bg-white shadow-sm p-4">
      <!-- Section Header -->
      <h1 class="text-2xl font-bold mb-6">Settings</h1>
  
      <!-- Social Connections -->
      <div class="mb-8">
        <h2 class="text-xl font-semibold mb-4">Social Connections</h2>
        <div class="space-y-4">
          <!-- Google Connection -->
          <div class="flex items-center p-4 border rounded-lg shadow-sm bg-gray-50">
            <img src="/img/google.svg" alt="Google" class="w-8 h-8 mr-4"/>
            <span class="flex-1">Connect your Google account</span>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Connect</button>
          </div>
  
          <!-- Kakao Connection -->
          <div class="flex items-center p-4 border rounded-lg shadow-sm bg-gray-50">
            <img src="/img/Kakao.svg" alt="Kakao" class="w-8 h-8 mr-4"/>
            <span class="flex-1">Connect your Kakao account</span>
            <button class="bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded">Connect</button>
          </div>
  
          <!-- GitHub Connection -->
          <div class="flex items-center p-4 border rounded-lg shadow-sm bg-gray-50">
            <img src="/img/github.svg" alt="GitHub" class="w-8 h-8 mr-4"/>
            <span class="flex-1">Connect your GitHub account</span>
            <button class="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded">Connect</button>
          </div>
        </div>
      </div>
  
      <!-- API Key Section -->
      <div>
        <h2 class="text-xl font-semibold mb-4">API Key</h2>
        <div class="bg-gray-50 p-4 border rounded-lg shadow-sm">
          <p class="text-gray-700 mb-2">Generate a new API key or view your existing keys.</p>
          <button @click="generateApiKey" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Generate New API Key
          </button>
          <div class="mt-4">
            <h3 class="text-lg font-medium mb-2">Current API Key</h3>
            <input type="text" readonly v-model="apiKey" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  
  // Mock API Key (for demo purposes)
  const apiKey = ref('Your-Current-API-Key');
  
  // Function to generate a new API key (mock implementation)
  function generateApiKey() {
    // In a real application, replace this with an API call to generate a new key
    apiKey.value = 'New-Generated-API-Key-' + Math.random().toString(36).substr(2, 9);
  }
  </script>
  
  <style scoped>
  /* Add additional styles if needed */
  </style>
  