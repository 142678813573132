<template>
    <div class="bg-white shadow-sm p-4" :key="`Profile_${pageinfo.key}`">
      <div class="flex justify-center mb-6 mt-4 relative">
        <div class="w-full h-32 overflow-hidden">
          <BackgroundImage :url="pageinfo.user.background" @imageSelected="handleImageSelected" />
        </div>
      </div>
      <div class="flex justify-center mb-6" style="margin-top:-100px; position: relative; z-index: 10;">
        <div class="w-32 h-32 rounded-full overflow-hidden border-gray-600 border-4">
          <ProfilePhoto :email="pageinfo.user.email" :url="pageinfo.user.photo" :width="150" :height="150" />
        </div>
      </div>

      <form @submit.prevent="validateForm" class="space-y-4">
        <div class="form-group">
          <label for="email" class="block text-gray-700">Email</label>
          <div class="flex items-center mt-1">
            <input v-model="pageinfo.user.email" type="email" readonly="true" disabled="true" id="email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your email">
            <span class="ml-2 text-xs text-green-500" v-if="pageinfo.user.emailConfirmed">Verified</span>
            <span class="ml-2 text-xs text-red-500" v-else>Not Verified</span>
          </div>
        </div>
        <div class="form-group mt-5">
          <label for="nickname" class="block text-gray-700">Nickname</label>
          <input v-model="pageinfo.user.nickName" type="text" id="nickname" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your nickname">
          <p v-if="errors.nickname" class="text-red-500 text-xs">{{ errors.nickname }}</p>
        </div>
        <div class="form-group mt-5">
          <label for="phone" class="block text-gray-700">Phone Number</label>
          <div class="flex items-center mt-1">
            <input v-model="pageinfo.user.phoneNumber" type="tel" id="phone" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your phone number">
            <span class="ml-2 text-xs text-green-500" v-if="pageinfo.user.phoneNumberConfirmed">Verified</span>
            <span class="ml-2 text-xs text-red-500" v-else>Not Verified</span>
          </div>
        </div>
        <div class="form-group mt-5">
          <label for="occupation" class="block text-gray-700">Job</label>
          <input v-model="pageinfo.user.job" type="text" id="occupation" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your occupation">
        </div>
        <div class="form-group mt-5">
          <label for="bio" class="block text-gray-700">Bio</label>
          <textarea v-model="pageinfo.user.bio" id="bio" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" rows="3" placeholder="Write a short bio"></textarea>
        </div>
        <div class="flex justify-center mt-6">
          <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded uppercase">Save</button>
        </div>
      </form>
    </div>
</template>

<script setup lang="ts">
import { ref,reactive,computed,onMounted } from 'vue';
import store from '@/store';
import { Member } from '@/entities';
import { ProfilePhoto,BackgroundImage } from '@/components';
import { MemberRepository } from '@/repositories';
import { MessageBox } from '@/utility';
import { useI18n } from 'vue-i18n';

const userinfo = computed(() => store.getters.userinfo);
const memberRep = new MemberRepository();
const { t, locale } = useI18n();

const errors = reactive({
  nickname : ''
});

var pageinfo = ref({
  key:0,
  user:new Member()
});

onMounted(async () => {
  pageinfo.value.user = userinfo.value as Member;
  console.log('USER : ', pageinfo.value.user);
  pageinfo.value.key += 1;
});


const validateForm = async () => {
  clearErrors();
  
  if (!userinfo.value.nickName) errors.nickname = 'Nickname is required.';

  if (Object.values(errors).every(error => !error)) {
    let rst = await memberRep.UpdateMemberInfo(pageinfo.value.user);
    if (rst !== null && rst !== undefined) {
      if (rst.check) {
        MessageBox.Success(t('system.Saved'));
      } else {
        MessageBox.Alert(rst.message);
      }
    }
  }
};

const clearErrors = () => {
  errors.nickname = '';
};

const handleImageSelected = (image:string) => {
    pageinfo.value.user.background = image;
    console.log('pageinfo.value.user.background : ', pageinfo.value.user.background)
};
</script>

<style scoped>
.form-input {
  border: 1px solid #d1d5db;
  padding: 0.5rem;
  border-radius: 0.375rem;
}
.form-input:focus {
  border-color: #3b82f6;
  outline: none;
}
.form-textarea {
  border: 1px solid #d1d5db;
  padding: 0.5rem;
  border-radius: 0.375rem;
}
.form-textarea:focus {
  border-color: #3b82f6;
  outline: none;
}
</style>
